import MainSkeleton from '@components/Skeleton/MainSkeleton';
import PageLayout from '@layouts/PageLayout';
import { navigate } from 'gatsby';
import React, { useLayoutEffect } from 'react';

export default function Index() {
  useLayoutEffect(() => {
    const isClientSide = typeof window !== 'undefined';

    if (window.location.pathname === '/' && isClientSide) {
      const languageCode = navigator.language.split('-')[0].toLowerCase();
      const browserLocale = (languageCode === 'ko' && 'ko') || (languageCode === 'ja' && 'ja') || 'en';

      navigate(`/${browserLocale}`, { replace: true });
    }
  }, []);

  return (
    <PageLayout style={{ display: 'grid', placeContent: 'center' }}>
      <MainSkeleton />
    </PageLayout>
  );
}
