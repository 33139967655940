import React, { useLayoutEffect } from 'react';
import { atom, useAtom } from 'jotai';
import ContentLoader from 'react-content-loader';

const MainSkeleton = () => {
  return (
    <>
      <ContentLoader viewBox="0 0 398 746" speed={0.8} width="100vw" height="100vh" backgroundColor="#f4f4f4" foregroundColor="#ecebeb">
        <rect x="0" y="56" rx="4" ry="4" width="14.5%" height="280" fill="#ededed" /> {/* 왼쪽 포스터 */}
        <rect x="17.5%" y="36" rx="5" ry="5" width="64%" height="320" fill="#ededed" /> {/* 포스터 */}
        <rect x="16%" y="48" rx="5" ry="5" width="29.6%" height="26" fill="#fbfafa" /> {/* 포스터 플레이장소 */}
        <rect x="84.5%" y="60" rx="4" ry="4" width="15.3%" height="280" fill="#ededed" /> {/* 오른쪽 포스터 */}
        <rect x="82.6%" y="70" rx="4" ry="4" width="25%" height="23" fill="#ffffff" /> {/* 오른쪽 포스터 플레이장소 */}
        <rect x="4.26%" y="390" rx="4" ry="4" width="12%" height="12" fill="#ededed" /> {/* 탭 버튼 1 */}
        <rect x="21.86%" y="390" rx="4" ry="4" width="12%" height="12" fill="#ededed" /> {/* 탭 버튼 2 */}
        <rect x="38.26%" y="390" rx="4" ry="4" width="12%" height="12" fill="#ededed" /> {/* 탭 버튼 3 */}
        <rect x="54.86%" y="390" rx="4" ry="4" width="12%" height="12" fill="#ededed" /> {/* 탭 버튼 4 */}
        <rect x="0" y="417" rx="4" ry="4" width="100%" height="1" fill="#ededed" /> {/* divider */}
        <rect x="4.26%" y="445" rx="4" ry="4" width="21.3%" height="17" fill="#ededed" /> {/* 인사말 */}
        <rect x="7.46%" y="510" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르1-1 */}
        <rect x="30.93%" y="510" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르1-2 */}
        <rect x="54.13%" y="510" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르1-3 */}
        <rect x="77.6%" y="510" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르1-4 */}
        <rect x="7.46%" y="600" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르2-1 */}
        <rect x="30.93%" y="600" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르2-2 */}
        <rect x="54.13%" y="600" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르2-3 */}
        <rect x="77.6%" y="600" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르2-4 */}
        <rect x="7.46%" y="690" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르3-1 */}
        <rect x="30.93%" y="690" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르3-2 */}
        <rect x="54.13%" y="690" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르3-3 */}
        <rect x="77.6%" y="690" rx="4" ry="4" width="15%" height="7%" fill="#ededed" /> {/* 장르3-4 */}
      </ContentLoader>
    </>
  );
};

export default MainSkeleton;
